import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Routes,
  RouterModule
} from '@angular/router';

// MATERIAL MODULE
import { MaterialModule } from './material.module';
// COMPONENTS
import { NumberOnlyDirective } from './directives/number-only.directive';
import { ThousandsToKPipe } from './pipes/thousands-to-k.pipe';
import { TruncateDecimalsPipe } from './pipes/truncate-decimals.pipe';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FooterComponent } from './components/footer/footer.component';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { DialogsUtilsService } from './utilities/dialogsUtilsService';
import { BreadcrumbFilterComponent } from './components/breadcrumb-filter/breadcrumb-filter.component';
import { ListPagerComponent } from './components/list-pager/list-pager.component';
import { PrettyDatePipe, PrettyDateShortNumbersPipe, PrettyDateShortPipe } from './pipes/prettyDate.pipe';
import { DialogConfirmActionComponent } from './components/dialog-confirm-action/dialog-confirm-action.component';
import { FilteredBannerComponent } from './components/filtered-banner/filtered-banner.component';
import { AddWhiteSpacesPipe } from './pipes/addWhiteSpaces.pipe';
import { MomentDatePipe } from './pipes/momentDate.pipe';
import { BreadcrumbDialogComponent } from './components/breadcrumb-dialog/breadcrumb-dialog.component';
import { DateHeaderComponent } from './directives/date-header/date-header.component';
import { DialogConfirmDeleteComponent } from './components/dialog-confirm-delete/dialog-confirm-delete.component';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DialogWarnActionComponent } from './components/dialog-warn-action/dialog-warn-action.component';
import { DialogRejectCommentComponent } from './components/dialog-reject-comment/dialog-reject-comment.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DialogInputActionComponent } from './components/dialog-input-action/dialog-input-action.component';
import { NewlineToBrPipe } from './pipes/newlineToBrPipe';




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule,
        FileUploadModule
    ],
    declarations: [
        BreadcrumbsComponent,
        NumberOnlyDirective,
        ThousandsToKPipe,
        AddWhiteSpacesPipe,
        SlugifyPipe,
        TruncateDecimalsPipe,
        PrettyDatePipe,
        PrettyDateShortPipe,
        PrettyDateShortNumbersPipe,
        MomentDatePipe,
        NavHeaderComponent,
        PageTitleComponent,
        ColorPickerComponent,
        FooterComponent,
        LoaderComponent,
        LoadingOverlayComponent,
        BreadcrumbFilterComponent,
        ListPagerComponent,
        DialogConfirmActionComponent,
        DialogWarnActionComponent,
        FilteredBannerComponent,
        BreadcrumbDialogComponent,
        DateHeaderComponent,
        DialogConfirmDeleteComponent,
        HeaderComponent,
        DialogRejectCommentComponent,
        DialogInputActionComponent,
        NewlineToBrPipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        BreadcrumbsComponent,
        BreadcrumbFilterComponent,
        NumberOnlyDirective,
        ThousandsToKPipe,
        SlugifyPipe,
        TruncateDecimalsPipe,
        PrettyDatePipe,
        PrettyDateShortPipe,
        PrettyDateShortNumbersPipe,
        MomentDatePipe,
        AddWhiteSpacesPipe,
        NavHeaderComponent,
        PageTitleComponent,
        ColorPickerComponent,
        FooterComponent,
        LoaderComponent,
        LoadingOverlayComponent,
        ListPagerComponent,
        DialogConfirmActionComponent,
        DialogWarnActionComponent,
        FilteredBannerComponent,
        BreadcrumbDialogComponent,
        DateHeaderComponent,
        DialogConfirmDeleteComponent,
        HeaderComponent,
        DialogRejectCommentComponent,
        DialogInputActionComponent,
        NewlineToBrPipe
    ],
    providers: [
        PrettyDatePipe,
        MomentDatePipe,
        AddWhiteSpacesPipe,
        DialogsUtilsService,
    ]
})
export class CoreModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

  }
}
