import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IBreadCrumbDialogParam, IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';
import { IInputDialog } from '../../interfaces/ConfirmDialog';

@Component({
  selector: 'app-dialog-input-action',
  templateUrl: './dialog-input-action.component.html',
  styleUrls: ['./dialog-input-action.component.scss']
})
export class DialogInputActionComponent implements OnInit, OnDestroy, IBreadCrumbDialogParam {
  inputDataDialog: IInputDialog;
  breadCrumbsParams: IBreadCrumbParam[];
  breadCrumbsWidth: string;
  errorMatch = false;
  keypass = new UntypedFormControl('', Validators.required)
  private subscription: Subscription;
  constructor(

    private dialogRef: MatDialogRef<DialogInputActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data[0]) {
      this.inputDataDialog = this.data[0];
    }
    if (this.data[1]) {
      this.setBreadCrumbsBannerByParams(this.data[1]);
      this.breadCrumbsWidth = this.data[2];
    }

    // this.subscription = this.keypass.valueChanges.subscribe(value => {
    //   if (value.toUpperCase() === this.inputDataDialog.keyword.toUpperCase()) {
    //     this.errorMatch = false;
    //   } else {
    //     this.errorMatch = true;
    //   }
    // })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.keypass.reset();
  }


  setBreadCrumbsBannerByParams(_breadCrumbsParams?: IBreadCrumbParam[]) {
    if (!!_breadCrumbsParams) {
      this.breadCrumbsParams = _breadCrumbsParams;
    }
  }


  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    if (this.keypass.valid && !this.keypass.invalid) {
      this.dialogRef.close(this.keypass.value);

    }
  }



}
