import { Router } from '@angular/router';
import { Paths } from './core/apis/paths';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from './session.service';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FileUploader } from 'ng2-file-upload';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // Properties
  private SERVER_URL = environment.api.url;
  public uploader: FileUploader;

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  // Methods

  /*
  * Make an HTTP request as POST in order to authenticate the user
  * @param params URLSearchParams
  * @returns Observable<T>
  */
  public login(params: { email: string, password: string }): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('X-Requested-With', 'XMLHttpRequest');
    const url = this.SERVER_URL + Paths.login;

    const observable: Observable<any> = this.http.post(
      url, params, { headers: headers }).pipe(map(response => response));
    return observable;
  }

  /**
   * Make the request in order to send an email with the recovery pass instructions
   * add the link that user should open with the email.
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param recoveryObj { email: string }
   * @returns Observable<IResponseMessage>
   */
  // public recoverPassword(recoveryObj: { email: string }): Observable<IResponseMessage> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
  //     .set('X-Requested-With', 'XMLHttpRequest');
  //   const url = this.SERVER_URL + Paths.recoveryPassword;
  //   const params = recoveryObj;

  //   const observable = this.http.post(
  //     url, params, { headers: headers })
  //     .pipe(
  //       map(response => <IResponseMessage>response),
  //       catchError(this.handlerError())
  //     );
  //   return observable;
  // }

  /**
   * Make the request in order to change the password & add the token in headers
   * @param recoveryObj { token: string, password: string }
   */
  public resetPassword(params: { token: string, password: string }): Observable<ITokenResponseMessage> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('X-Requested-With', 'XMLHttpRequest');
    const url = this.SERVER_URL + Paths.changePassword;

    const observable = this.http.post(
      url, params, { headers: headers })
      .pipe(
        map(response => <ITokenResponseMessage>response),
        catchError(this.handlerError())
      );

    return observable;
  }

  /**
 * Make a POST request with a generic type
 * @param url string
 * @param params
 * @param headers Headers?
 */
  public unsecuredPost(url: string, params?): Observable<any> {
    const headers = new HttpHeaders()
      .set('X-Requested-With', 'XMLHttpRequest');

    url = this.SERVER_URL + url;
    const observable = this.http.post(
      url, params, { headers: headers }).pipe(map(response => response),
        catchError((error: any) => {
          console.log(url, error, error.status);
          if (error.status === 404) {
            console.log('not found', url);
            this.router.navigate(['/not-found']);
          }
          if (error.status === 401 || error.status === 403) {
            this.cookieService.deleteAll();
            console.log('unauthorized', error);
            this.router.navigate(['/auth']);
          }
          return throwError(error);
        }));

    return observable;
  }
  /**
   * Make a GET request
   * @param url string
   * @param params? any
   */
  public unsecuredGet(url: string, query?: any): Observable<any> {
    const params = this.setURLParams(query);
    const headers = new HttpHeaders()
      .set('X-Requested-With', 'XMLHttpRequest');

    url = this.SERVER_URL + url;
    const observable = this.http.get(url, { headers, params })
      .pipe(
        map(response => response),
        // retryWhen(errors => errors.pipe(delay(1000), take(1), concat( this.handlerGetError(errors, url)))),
        catchError((error: any) => {
          return this.handlerGetError(error, url);
        }),
      );

    return observable;
  }
  /**
   * Make a POST request with a generic type
   * @param url string
   * @param params
   * @param headers Headers?
   */
  public post(url: string, params?): Observable<any> {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json');

    url = this.SERVER_URL + url;
    const observable = this.http.post(
      url, params, { headers: headers }).pipe(map(response => response),
        catchError((error: any) => {
          console.log(url, error, error.status);
          if (error.status === 404) {
            console.log('not found', url);
            this.router.navigate(['/not-found']);
          }
          if (error.status === 401 || error.status === 403) {
            this.cookieService.deleteAll();
            console.log('unauthorized', error);
            this.router.navigate(['/auth']);
          }
          return throwError(error);
        }));

    return observable;
  }

  /**
   * Make a PUT request with a generic type
   * @param url string
   * @param params
   * @param headers Headers?
   */
  public put(url: string, params?): Observable<any> {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest');
    url = this.SERVER_URL + url;

    const observable = this.http.put(
      url, params, { headers: headers }).pipe(map(response => response),
        catchError((error: any) => {
          console.log(url, error, error.status);
          if (error.status === 404) {
            console.log('not found', url);
            this.router.navigate(['/not-found']);
          }
          if (error.status === 401 || error.status === 403) {
            this.cookieService.deleteAll();
            console.log('unauthorized', error);
            this.router.navigate(['/auth']);
          }
          return throwError(error);
        }));

    return observable;
  }

  /**
   * Make a GET request
   * @param url string
   * @param params? any
   */
  public get(url: string, query?: any): Observable<any> {
    const params = this.setURLParams(query);
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Authorization', `Basic ${token}`)
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
      .set('Pragma', 'no-cache')
      .set('Expires', '0')
      .set('Content-Type', 'application/json;charset=UTF-8');

    url = this.SERVER_URL + url;
    const options = { headers, params };
    const observable = this.http.get(url, { headers: headers, params })
      .pipe(
        map(response => response),
        // retryWhen(errors => errors.pipe(delay(1000), take(1), concat( this.handlerGetError(errors, url)))),
        catchError((error: any) => {
          return this.handlerGetError(error, url);
        }),
      );
    return observable;
  }

  public getZip(url: string) {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest');


    return this.http.get(this.SERVER_URL + url, { headers, responseType: 'blob' })
      .pipe(map(response => response));
  }
  /**
   * Make a GET request for xlsx
   * @param url string
   */
  public getXlsx(url: string, query?: any) {
    const params = this.setURLParams(query);

    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest');
    url = this.SERVER_URL + url;

    return this.http.get(url, { headers, responseType: 'blob', params })
      .pipe(map(response => response));
  }

  /**
 * Make a GET request for pdf
 * @param url string
 */
  public getPDF(url: string): Observable<Blob> {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/octet-stream');
    url = this.SERVER_URL + url;
    const options = { responseType: 'Blob' };
    return this.http.get(url, { headers, responseType: 'blob' })
      .pipe(map(response => response));
  }


  /**
 * Make a GET request for any blob
 * @param url string
 */
  public getBlobFromURL(url: string): Observable<Blob> {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest');
    url = url;

    return this.http.get(url, { headers, responseType: 'blob' })
      .pipe(map(response => response));
  }


  /**
   * Make a GET request in order to check the token
   * @param url string
   * @param params? URLSearchParams
   */
  public checkToken(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('X-Requested-With', 'XMLHttpRequest');
    const url = `${this.SERVER_URL}${Paths.checkToken}/${token}`;

    const observable = this.http.get(
      url, { headers: headers })
      .pipe(
        map(response => response),
        catchError(this.handlerError())
      );

    return observable;
  }

  /**
   * Handler the error an return the json as IResponseMessage
   */
  private handlerError() {
    return (err: any) => {
      return throwError({ ...err.json(), status: err.status });
    };
  }



  /**
   * Convert a javascript object in a URLSearchParams
   * @param query: any an object with the values and keys
   * @returns : URLSearchParams
   */
  setURLParams(query: any): HttpParams {
    let params = new HttpParams();
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const value = query[key];
        if (value !== null) { params = params.set(key, value); }
      }
    }
    return params;
  }

  /**
   * Return a instance of FileUploader
   * @param type: string
   * @returns : FileUploader
   */
  public uploadFile(type: string): FileUploader {

    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const tokenNative = this.cookieService.get('token');
    if (!tokenNative) {
      return;
    }
    this.uploader = new FileUploader({
      url: environment.api.url + '/upload',
      headers: [
        { name: 'Authorization', value: `Basic ${btoa('BROWSER' + ':' + tokenNative)}` },
        { name: 'X-Requested-With', value: 'XMLHttpRequest' },
        { name: 'Access-Control-Allow-Origin', value: '*' },
        { name: 'Cache-Control', value: 'no-cache, no-store,  post-check=0, pre-check=0' },
        { name: 'Pragma', value: 'no-cache' },

      ],
      additionalParameter: { type: type },
      itemAlias: 'upload_file'
    });

    this.uploader.onBeforeUploadItem = (file) => {
      if (file._file.size > 5000000) {
        file.cancel();
        console.log('error');
        this.uploader.cancelAll();
        throw 'size error';
        return;
      }
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    return this.uploader;
  }

  /**
   * Make a DELETE request with a generic type
   * @param url string
   * @return : Observable<any>
   */
  public delete(url: string): Observable<any> {
    const token = btoa('BROWSER' + ':' + this.cookieService.get('token'));
    const headers = new HttpHeaders()
      .set('Authorization', `Basic ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest');
    url = this.SERVER_URL + url;

    const observable = this.http.delete(url, { headers: headers }).pipe(map(response => response),
      catchError((error: any) => {
        console.log(url, error, error.status);
        if (error.status === 404) {
          console.log('not found', url);
          this.router.navigate(['/not-found']);
        }
        if (error.status === 401 || error.status === 403) {
          this.cookieService.deleteAll();
          console.log('unauthorized', error);
          this.router.navigate(['/auth']);
        }
        return throwError(error);
      }));

    return observable;
  }
  /**
* Handler the error an return the json as IResponseMessage
*/
  private handlerGetError(error, url?) {
    console.log(url, error, error.status);
    if (error.status === 308) {
      console.log('redirect');
    }
    if (error.status === 404) {
      console.log('not found', url);
      this.router.navigate(['/not-found']);
    }
    if (error.status === 401 || error.status === 403) {
      this.cookieService.deleteAll();
      console.log('unauthorized', error);
      this.router.navigate(['/auth']);
    }
    return throwError(error);
  }


}



export interface IResponseMessage {
  msg: string;
}

export interface IToken {
  token: string;
}

export interface ITokenResponseMessage {
  msg: string;
  token: string;
}
