<div class="bread-crumb" [ngStyle]="{'width': breadCrumbsWidth}">
    <div class="bread-crumb-dialog">
        <app-breadcrumb-dialog [breadcrumbItems]="breadCrumbsParams"></app-breadcrumb-dialog>
    </div>
</div>
<form class="dialog-form" novalidate>
    <div class="dialog-form-header">
        <h1 mat-dialog-title><i *ngIf="inputDataDialog.buttonIcon !== ''"
                class="fal fa-{{inputDataDialog.buttonIcon}}"></i>
            {{inputDataDialog.title}}</h1>
    </div>
    <mat-dialog-content class="dialog-form-body bc-white">
        <div class="dialog-body">
            <div class="dialog-row pad-md">
                <div class="dialog-container">
                    <div class="assistant-tutorial {{inputDataDialog.dialogClass}}">
                        <div class="assistant-tutorial-text">
                            <p class="xl bold">{{inputDataDialog.bodyMessage}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-row pad-md bg-gray border-bottom">
                <div class="dialog-container">
                    <div class="input-container">
                        <label><i class="fad fa-waveform assistant-h" matTooltip=" {{inputDataDialog.bodyTitle}}"
                                matTooltipClass="tooltip-info-light"></i> {{inputDataDialog.bodyTitle}}</label>
                        <!-- text input start -->
                        <div class="input input-lg input-gray-dark input-textarea">
                            <!-- <div class="input-lg-icon">
                                <i class="fal fa-info-circle icon-lg"></i>
                            </div> -->
                            <div class="input-lg-text right">
                                <textarea [formControl]="keypass" placeholder=" {{inputDataDialog.bodyTitle}}"
                                    type="text" maxlength="150">
                                    </textarea>
                            </div>
                        </div>
                        <!-- text input end -->
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-form-footer">
        <button mat-button mat-dialog-close class="dark1-alt" (click)="close()" tabindex="-1"
            type="button">Cancelar</button>
        <button mat-button mat-dialog-close class="warn-gradient" [disabled]="(!keypass.valid || keypass.invalid)"
            (click)="confirm()" type="button"> {{inputDataDialog.actionMessage}}</button>
    </mat-dialog-actions>
</form>