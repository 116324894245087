<div class="bread-crumb" [ngStyle]="{'width': breadCrumbsWidth}">
    <div class="bread-crumb-dialog">
        <app-breadcrumb-dialog [breadcrumbItems]="breadCrumbsParams"></app-breadcrumb-dialog>
    </div>
</div>

<div class="dialog-form">
    <div class="dialog-form-header">
        <h1 mat-dialog-title>Tienes cambios sin guardar</h1>
        <a (click)="close('false')" class="dialog-form-header-close">
            <i class="fad fa-times-circle"></i>
        </a>
    </div>
    <mat-dialog-content class="dialog-form-body bc-white">
        <p>Si abandonas esta página, se perderan todos los cambios no guardados</p>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-form-footer">
        <button mat-button mat-dialog-close class="dark1-alt" (click)="close('false')" type="button">Cancelar</button>
        <button mat-button mat-dialog-close class="warn-gradient" (click)="close('true')" type="button">Salir</button>
    </mat-dialog-actions>
</div>